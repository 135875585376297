//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
// import { i18n } from '@/i18n';
import i18n from '@/vueI18n'
// import { routerAsync } from '@/app-module';
import firebase from 'firebase/app';
import 'firebase/firestore';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import PhoneUnverifiedPage from '@/modules/auth/components/phone-unverified-page.vue';
import SignUpWithEmailPage from '@/modules/auth/components/signup-with-email-page.vue';

const { fields } = UserModel;

export default {
  name: 'app-signin-page',
  components: {
    [PhoneUnverifiedPage.name]: PhoneUnverifiedPage,
    [SignUpWithEmailPage.name]: SignUpWithEmailPage,
    VuePhoneNumberInput,

  },

  data() {
    return {
      step: 1,
      rules: {
        email: fields.email.forFormRules(),
        password: fields.password.forFormRules(),
      },
      Phonenumber:'',
      model: {},
       isPwd: true,

       TRansObject: {
        countrySelectorLabel: 'Code Country',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Phone Number',
        example: 'Example :',
      },
      TRansObjectAr: {
        countrySelectorLabel: 'كود البلد',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'رقم الجوال',
        example: 'مثال :',
      },
      CCode:'',
      isCCode:'',
      formatInternational: '',
      countryCode: ''
    };
  },
  computed: {
    ...mapGetters({
      loading: 'auth/loading',
    }),
    fields() {
      return fields;
    },
    isRTL(){
      return i18n.locale == 'ar'
    }
  },
  methods: {
    ...mapActions({
      doRegisterEmailAndPassword: 'auth/doRegisterEmailAndPassword',
      signPhoneNumber: 'auth/signPhoneNumber'
    }),
    prev() {this.step -= 1},
    next() {
      console.log('step befor: ', this.step)
      this.step += 1
      console.log('step after: ', this.step)
    },
    async doSubmit() {
      // try {
      //   await this.$refs.form.validate();
      // } catch (error) {
      //   return;
      // }

      window.recaptchaVerifier =  new firebase.auth.RecaptchaVerifier('sign-in-button', {
        'size': 'invisible',
      });

      let appVerifier =   window.recaptchaVerifier;
      // let newphone = "+2" + this.Phonenumber

      await firebase.auth().signInWithPhoneNumber( this.formatInternational, appVerifier).then((confirmationResult) => {
        localStorage.setItem("verfId", confirmationResult.verificationId)
        // routerAsync().push('/auth/phone-unverified')        
        this.next();
        window.confirmationResult = confirmationResult;
        console.log('confirmationResult', confirmationResult)
      }).catch(function () {
        // console.log(error)
      });
    },
    // i18n(code) {
    //   return i18n(code);
    // },
    i18n(key, args) {
        return this.$t(key, args);
    },   
    onUpdate(data) {
      console.log(data);
      
      this.countryCode = '+' + data.countryCallingCode;
      this.formatInternational = data.formatInternational
      // this.value.countryCode = data.countryCode;
      // this.model.alternativePhoneNumber = data.formatInternational
    },
  },
};

import { render, staticRenderFns } from "./signup-page.vue?vue&type=template&id=09a2059c&scoped=true&"
import script from "./signup-page.vue?vue&type=script&lang=js&"
export * from "./signup-page.vue?vue&type=script&lang=js&"
import style0 from "./signup-page.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./signup-page.vue?vue&type=style&index=1&id=09a2059c&scoped=true&lang=css&"
import style2 from "./signup-page.vue?vue&type=style&index=2&id=09a2059c&lang=scss&scoped=true&"
import style3 from "./signup-page.vue?vue&type=style&index=3&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09a2059c",
  null
  
)

export default component.exports
import {QImg,QForm,QInput,QIcon,QBtn,QSpinnerIos,QField,QColor,QCheckbox} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QImg,QForm,QInput,QIcon,QBtn,QSpinnerIos,QField,QColor,QCheckbox})

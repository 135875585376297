//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions , mapMutations} from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
import { i18n } from '@/i18n';
const { fields } = UserModel;

export default {
  name: 'app-signup-with-email-page',
  props: ['countryCode'],

  data() {
    return {
      rules: {
        email: fields.email.forFormRules(),
        password: fields.password.forFormRules(),
      },
      model: {},
      step: 1,
      isPwd: true,
      isConfirmPwd: true,
      confirmPassword: '',
    };
  },

  computed: {
    ...mapGetters({
      loading: 'auth/loading',
      currentUser: 'auth/currentUser'
    }),
    fields() {
      return fields;
    },
  },
  mounted() {
    this.AUTH_END()
  },
  created(){
    console.log(this.currentUser);
  },
  methods: {
    ...mapMutations({  AUTH_END:'auth/AUTH_END'}),
    ...mapActions({
      doRegisterEmailLinkedPhone: 'auth/doRegisterEmailLinkedPhone',
      // doRegisterEmailAndPassword: 'auth/doRegisterEmailAndPassword',
    }),
    i18n(code) {
      return i18n(code);
    },
    prev() {this.step -= 1},
    next() {this.step += 1},

    async doSubmit() {
      debugger
      // try {
      //   await this.$refs.form.validate();
      // } catch (error) {
      //   return;
      // }
      if (this.step == 1) {
        debugger
        this.next();
        return
      }
      // this.model.accountType = 'tourGuide';
      this.model.accountType = 'company';
      // this.model.roles = ['owner']
      this.model.countryCode = this.countryCode
      console.log(this.currentUser);
      debugger
      await this.doRegisterEmailLinkedPhone({
        email: this.model.email,
        password: this.model.password,
        countryCode: this.model.countryCode
      });
      debugger
    },
  },
};
